import {FC, ReactNode, RefObject, useRef} from "react"

import {Styles, css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

interface Props {
  value: string
  maxLength?: number
  placeholder?: string
  onFocus?: () => void
  onChange: (value: string) => void
  onEnter?: () => void
  disabled?: boolean
  inputRef?: RefObject<HTMLTextAreaElement | null>
  rightComponent?: ReactNode
  className?: string
}

export const InputField: FC<Props> = ({
  value,
  maxLength,
  placeholder,
  onFocus,
  onChange,
  onEnter,
  disabled,
  inputRef,
  rightComponent,
  className,
}) => {
  const divRef = useRef<HTMLDivElement>(null)
  const maxWidth = (divRef.current?.clientWidth ?? 0) - 16

  // I decided to use this "hack", because changing the size of the textarea comes with some problems,
  // like the vertical centering of the placeholder and content, as keep the padding around the text

  const onFocusTextArea = (): void => {
    inputRef?.current?.focus()
  }

  const sharedTextAreaStyles: Styles = {
    gridArea: "1 / 1 / 2 / 2",
    margin: 0,
    padding: 0,
    border: "none",
    outline: "none",
    flexGrow: 0,
    width: maxWidth,
    wordBreak: "break-word",
    backgroundColor: "none",
    appearance: "none",
    lineHeight: "20px",
    maxHeight: 100,
  }

  return (
    <div
      className={cx(
        css({
          display: "flex",
          flexDirection: "row",
          borderRadius: 12,
          backgroundColor: "#FFFFFF",
          borderColor: "border.brand.secondarySolid",
          borderWidth: 2,
          borderStyle: "solid",
          backdropFilter: "blur(15px)",
          boxSizing: "border-box",
          alignItems: "center",
          cursor: "text",
          padding: {
            base: "14px 12px 14px 12px",
            desktop: "16px 20px 16px 20px",
          },
          transition: "box-shadow ease-in 0.2s",
          "&:focus-within": {
            boxShadow:
              "0px 0px 0px 6px token(colors.background.brand.secondary)",
          },
        }),
        className,
      )}
      onClick={onFocusTextArea}
      ref={divRef}
    >
      <div
        className={css({
          display: "inline-grid",
          alignItems: "stretch",
          flexGrow: 0,
          width: "100%",
          outline: "none",
        })}
      >
        <textarea
          data-test-id="chat-input"
          rows={1}
          maxLength={maxLength}
          value={value}
          placeholder={placeholder}
          ref={inputRef}
          onFocus={onFocus}
          onChange={(event) => onChange(event.target.value)}
          onKeyDown={(event): void => {
            if (event.key === "Enter" && !event.shiftKey && onEnter) {
              event.preventDefault()

              if (!disabled) {
                onEnter()
              }
            }
          }}
          className={css(sharedTextAreaStyles, {
            resize: "none",
            overflow: "hidden",
            textStyle: "bodyLight",
            "&::placeholder": {
              color: "text.quaternary",
            },
          })}
        />
        <div
          className={css(sharedTextAreaStyles, {
            whiteSpace: "pre-wrap",
            visibility: "hidden",
          })}
        >
          {value}{" "}
        </div>
      </div>

      {rightComponent && (
        <div
          className={hstack({
            alignItems: "flex-end",
            height: "100%",
            marginY: 0,
            minHeight: 29,
            alignSelf: "end",
          })}
        >
          {rightComponent}
        </div>
      )}
    </div>
  )
}
