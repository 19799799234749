import {useBreadcrumbs} from "@frontend/app-container/context"
import {useTranslation} from "@frontend/i18n"
import {assertOrNotFound} from "@frontend/utils/notFound"
import {trpc} from "@frontend/utils/trpc"

import {makeBreadcrumbs} from "./breadcrumbs"

export const useSetBreadcrumbs = ({
  caseId,
  causeOnMessageId,
}: {
  caseId: string
  causeOnMessageId: number | "peek"
}): void => {
  const t = useTranslation()

  const [remediationPageData] = trpc.remediationPageData.useSuspenseQuery({
    caseId,
    causeOnMessageId,
  })

  assertOrNotFound(remediationPageData)

  useBreadcrumbs(
    makeBreadcrumbs({
      caseId,
      caseName: remediationPageData.caseName,
      cause:
        remediationPageData.type.type === "remediation"
          ? {
              id: remediationPageData.type.causeOnMessageId,
              name: remediationPageData.type.cause.name,
            }
          : "peek",
      remediationType: "doItYourself",
      t,
    }),
  )
}
