import {TRPCClientError} from "@trpc/react-query"
import {Component, PropsWithChildren} from "react"

import {NotFoundError} from "@frontend/utils/notFound"

import {ErrorPage} from "./error-page"
import {LocationObserver} from "./location-observer"

interface State {
  state: "noError" | "notFound"
}

export class NotFoundErrorBoundary extends Component<PropsWithChildren, State> {
  constructor(props: PropsWithChildren) {
    super(props)

    this.state = {
      state: "noError",
    }
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      state: isNotFoundError(error) ? "notFound" : "noError",
    }
  }

  componentDidCatch(error: Error): void {
    if (isNotFoundError(error)) {
      return
    }

    throw error
  }

  render(): React.ReactNode {
    if (this.state.state === "noError") {
      return this.props.children
    }

    return (
      <>
        <ErrorPage errorType="notFound" />
        <LocationObserver
          onChangeLocation={() => {
            this.setState({state: "noError"})
          }}
        />
      </>
    )
  }
}

const isNotFoundError = (error: Error): boolean =>
  error instanceof NotFoundError ||
  (error instanceof TRPCClientError && error.data?.code === "NOT_FOUND")
