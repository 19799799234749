import {useEffect, useRef, useState} from "react"

export const useTick = (shouldTick = true): void => {
  const setSeconds = useState(0)[1]

  const intervalRef = useRef<NodeJS.Timeout | number | null>(null)

  useEffect(() => {
    if (shouldTick) {
      intervalRef.current = setInterval(() => {
        setSeconds((s) => s + 1)
      }, 1000)
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    return (): void => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTick])
}
