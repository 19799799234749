import {FC, PropsWithChildren, ReactNode} from "react"

import {RecipeVariantProps, Styles, css, cva} from "@styled-system/css"

import {ButtonActionProps} from "./buttonProps"
import {ButtonVariantsProps, buttonColors, disabledStyles} from "./styles"
import {useHandleClick} from "./useHandleClick"

const baseButtonStyles = cva({
  base: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    cursor: "pointer",
  },
  variants: {
    size: {
      small: {
        padding: 8,
      },
      large: {
        padding: 12,
      },
    },
  },
  defaultVariants: {
    size: "small",
  },
})

type ButtonStylesProps = RecipeVariantProps<typeof baseButtonStyles> &
  ButtonVariantsProps

type Props = ButtonStylesProps &
  ButtonActionProps & {
    title?: string
    disabled?: boolean
    css?: Styles
    icon: ReactNode
    ariaLabel?: string
    testId?: string
  }

export const IconButton: FC<PropsWithChildren<Props>> = ({
  variant,
  size,
  disabled,
  icon,
  css: cssProp,
  ariaLabel,
  testId,
  ...actionProps
}) => {
  const handleClick = useHandleClick({
    navigateTo:
      "navigateTo" in actionProps ? actionProps.navigateTo : undefined,
    external: "external" in actionProps ? actionProps.external : undefined,
    onClick: actionProps.onClick,
  })
  return (
    <button
      data-test-id={testId}
      onClick={handleClick}
      disabled={disabled}
      aria-label={ariaLabel}
      className={css(
        buttonColors.raw({variant}),
        baseButtonStyles.raw({size}),
        disabled && disabledStyles.raw({variant}),
        cssProp,
      )}
    >
      {icon}
    </button>
  )
}
