import type {DiyStep, DiyStepCaution} from "@ri2/rc-db"
import {FC} from "react"
import Markdown from "react-markdown"

import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"

import {CautionChip} from "./caution-chip"
import {getStepTitle} from "../util/get-step-title"

interface Props {
  step: DiyStep
  index: number
  id?: string
}

export const Step: FC<Props> = ({
  step,
  step: {contentMarkdown, cautions},
  index,
  id,
}) => {
  const sortedCautions: DiyStepCaution[] = cautions
  sortedCautions.sort()

  const t = useTranslation()

  return (
    <div
      id={id}
      className={css({
        display: "flex",
        flexDirection: "column",
        paddingBottom: 16,
        borderBottomWidth: 1,
        borderBottomColor: "lineGrey",
        borderBottomStyle: "solid",
        gap: 8,
        width: "100%",
      })}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: 4,
        })}
      >
        <div
          className={css({
            display: "flex",
            flexDirection: "row",
            gap: 8,
            flexWrap: "wrap",
            alignItems: "center",
          })}
        >
          <h3
            className={css({
              textStyle: "bodyLargeMedium",
              color: "text.primary",
            })}
          >
            {getStepTitle(step, index + 1, t)}
          </h3>
          {sortedCautions.map((caution) => (
            <CautionChip
              caution={caution}
              className={css({
                flexShrink: 0,
              })}
              key={caution}
            />
          ))}
        </div>
        <div
          className={css({
            textStyle: "bodyLargeLight",
            color: "fontGrey",
            maxWidth: 672,
            "& em": {
              fontWeight: 500,
            },
          })}
        >
          <Markdown>{contentMarkdown}</Markdown>
        </div>
      </div>
    </div>
  )
}
