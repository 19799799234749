import type {Product} from "@ri2/rc-db"
import {createTRPCReact} from "@trpc/react-query"

import type {AppRouter} from "@ri2/app/server/routers/_app"
import {Feedback} from "@ri2/db/client"

export const trpc = createTRPCReact<AppRouter>({})

/* eslint-enable @typescript-eslint/no-explicit-any */

export const useCreateCaseMutation = (
  onSuccess?: (result: {caseId: string}) => void,
): ReturnType<typeof trpc.createCase.useMutation> => {
  const utils = trpc.useUtils()

  return trpc.createCase.useMutation({
    onSuccess: ({caseId}, {userRcId}) => {
      invalidateCasePageQueries(utils, {
        userRcId,
      })

      onSuccess?.({caseId})
    },
  })
}

export const useAddToCartMutation = (): ReturnType<
  typeof trpc.addToCart.useMutation
> => trpc.addToCart.useMutation()

export const invalidateCasePageQueries = (
  utils: ReturnType<typeof trpc.useUtils>,
  {
    userRcId,
    caseId,
  }: {
    userRcId: string
    caseId?: string
  },
): void => {
  utils.caseSummaries.invalidate({
    userRcId,
  })

  if (caseId) {
    utils.casePageData.invalidate({
      id: caseId,
    })
  }
}

export const useCreateFeedbackMutation = (
  onSuccess?: (feedback: Feedback) => void,
): ReturnType<typeof trpc.createFeedback.useMutation> => {
  const utils = trpc.useUtils()

  return trpc.createFeedback.useMutation({
    onSuccess: (feedback, {on}) => {
      if (on) {
        utils.remediationPageData.invalidate()
        utils.casePageData.invalidate()
      }

      onSuccess?.(feedback)
    },
  })
}

export const useUpdateFeedbackMutation = (
  onSuccess?: (feedback: Feedback) => void,
): ReturnType<typeof trpc.updateFeedback.useMutation> => {
  const utils = trpc.useUtils()

  return trpc.updateFeedback.useMutation({
    onSuccess: (feedback) => {
      utils.remediationPageData.invalidate()
      utils.casePageData.invalidate()

      onSuccess?.(feedback)
    },
  })
}

export const useLookUpModelNumber = (
  onSuccess?: (product: Product | null) => void,
): ReturnType<typeof trpc.lookUpModelNumber.useMutation> =>
  trpc.lookUpModelNumber.useMutation({
    onSuccess: (product) => {
      onSuccess?.(product)
    },
  })

export const useAddModelNumberToCase = (
  onSuccess?: () => void,
): ReturnType<typeof trpc.addModelNumberToCase.useMutation> => {
  const utils = trpc.useUtils()

  return trpc.addModelNumberToCase.useMutation({
    onSuccess: () => {
      // reset rather than invalidate here because we want to force
      // loading screens until the data is fresh again
      utils.casePageData.reset()
      utils.remediationPageData.reset()

      onSuccess?.()
    },
  })
}
