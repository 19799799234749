import {FC} from "react"
import {Trans} from "react-i18next"
import {ReactSVG} from "react-svg"

import homeVector from "@frontend/design/icons/home-vector.svg"
import {useTranslation} from "@frontend/i18n"
import {trackEvent} from "@frontend/utils/trackEvent"
import {css, cx} from "@styled-system/css"
import {iconsColor} from "@styled-system/patterns"

export const SupportedExplanation: FC = () => {
  const t = useTranslation()

  const guideToLines = [
    "home.supported.supportedList.1",
    "home.supported.supportedList.2",
    "home.supported.supportedList.3",
    "home.supported.supportedList.4",
  ]
  const notSupportedLines = [
    [
      t("home.supported.notSupportedList.1"),
      t("home.supported.notSupportedLinks.1"),
      "chat_order_return_status",
    ],
    [
      t("home.supported.notSupportedList.2"),
      t("home.supported.notSupportedLinks.2"),
      "chat_shipping_return_questions",
    ],
    [
      t("home.supported.notSupportedList.3"),
      t("home.supported.notSupportedLinks.3"),
      "chat_other_cs_questions",
    ],
    [
      t("home.supported.notSupportedList.4"),
      t("home.supported.notSupportedLinks.4"),
      "chat_part_no_lookup",
    ],
  ] as const

  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        fontSize: "1.1em",
        width: "100%",
        marginTop: "0px",
        color: "text.quaternary",
      })}
    >
      <div
        className={css({
          ...containerCss,
          borderRadius: 10,
          background: "background.tertiary",
        })}
      >
        <h5
          className={css({
            fontWeight: "bold",
            marginBottom: 5,
          })}
        >
          <Trans t={t} i18nKey="home.supported.supportedHeader">
            <span
              className={css({
                color: "background.brand.accent",
              })}
            />
          </Trans>
        </h5>
        <ul>
          {guideToLines.map((line) => (
            <li
              key={line}
              className={css({
                display: "flex",
                alignItems: "center",
              })}
            >
              <ReactSVG
                src={homeVector}
                className={cx(
                  iconsColor({color: "background.quaternary"}),
                  css({
                    marginRight: 10,
                    "& svg": {
                      width: 10,
                      height: 10,
                    },
                  }),
                )}
              />
              <p>
                {
                  <Trans t={t} i18nKey={line}>
                    <span
                      className={css({
                        fontWeight: "bold",
                      })}
                    />
                  </Trans>
                }
              </p>
            </li>
          ))}
        </ul>
      </div>
      <div
        className={css({
          display: "flex",
          "@media (min-width: 896px)": {
            flexDirection: "row-reverse",
          },
          ...containerCss,
        })}
      >
        <div>
          <h5
            className={css({
              fontStyle: "italic",
              marginBottom: 5,
            })}
          >
            {t("home.supported.notSupportedHeader")}
          </h5>
          <ul
            className={css({
              textDecoration: "underline",
            })}
          >
            {notSupportedLines.map(([line, url, trackingEvent]) => (
              <li key={line}>
                <a
                  target="_blank"
                  href={url}
                  rel="noreferrer"
                  onClick={() => trackEvent(trackingEvent)}
                >
                  {line}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

const containerCss = {
  padding: 20,
  flexGrow: 1,
}
