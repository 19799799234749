import {FC, useEffect, useRef, useState} from "react"

import {useEffectOnMount} from "@frontend/utils/useEffectOnMount"

import {Feedback} from "./feedback"
import {ModalContainer} from "./modal-container"
import {On, State} from "./types"

interface Props {
  on: On
  onClose: () => void
}

export const FeedbackContainer: FC<Props> = ({on, onClose}) => {
  const [state, setState] = useState<State>({
    state: "feedback",
    feedback: on.feedback,
  })

  const onSubmitted = (): void => {
    setState({state: "thanks"})
  }

  useEffect(() => {
    if (state.state !== "thanks") {
      return
    }

    const timeout = setTimeout(() => {
      onClose()
    }, 2000)

    return (): void => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.state])

  const containerRef = useRef<HTMLDivElement>(null)

  useEffectOnMount(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({behavior: "smooth", block: "center"})
    }
  })

  useEffect(() => {
    if (state.state === "thanks") {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.state])

  if (state.state === "thanks") {
    return null
  }

  return (
    <ModalContainer on={on} onClose={onClose} onSubmitted={onClose}>
      <Feedback
        on={on}
        feedback={state.feedback}
        onSubmitted={onSubmitted}
        onCanceled={onClose}
      />
    </ModalContainer>
  )
}
