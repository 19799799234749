import {cloneDeep} from "lodash"
import {FC, useState} from "react"

import {useTranslation} from "@frontend/i18n"
import {useSession} from "@frontend/session"
import {useUpdateFeedbackMutation} from "@frontend/utils/trpc"
import {
  Feedback as FeedbackModel,
  FeedbackRating,
  SelectedReasons,
} from "@ri2/db/client"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {EvaluationOverall} from "./evaluation-overall"
import {Options} from "./options"
import {type On} from "./types"
import {Chip, Logo} from "../ui"
import {Button} from "../ui/button"

interface FeedbackProps {
  on?: On
  feedback: FeedbackModel
  onSubmitted: () => void
  onCanceled: () => void
  isInline?: boolean
}

export const Feedback: FC<FeedbackProps> = ({
  on,
  feedback,
  onSubmitted,
  onCanceled,
  isInline,
}) => {
  const {userRcId} = useSession()

  const updateFeedbackMutation = useUpdateFeedbackMutation(() => {
    onSubmitted()
  })

  const [rating, setRating] = useState(feedback.rating)
  const [comment, setComment] = useState(feedback.comment ?? "")

  const t = useTranslation()

  const reasons = t(
    on?.on === "remediation"
      ? `feedback.remediation.${on.type}.${rating}.reasons`
      : on?.on === "message"
        ? `feedback.message.${rating}.reasons`
        : `feedback.general.${rating}.reasons`,
    {
      returnObjects: true,
    },
  ) as Reason[]

  const [selectedReasons, setSelectedReasons] = useState<SelectedReasons>(
    createSelectedReasonsFromReasons(reasons, feedback?.reasons ?? null),
  )

  const onSetRating = (rating: FeedbackRating): void => {
    setRating(rating)
    setSelectedReasons(
      createSelectedReasonsFromReasons(reasons, feedback.reasons),
    )
  }

  const onToggleReason = (reason: Reason): void => {
    setSelectedReasons(toggleReason(selectedReasons, reason))
  }

  const onToggleOption = (reason: Reason, option: string): void => {
    setSelectedReasons(toggleOption(selectedReasons, reason, option))
  }

  const isReasonSelected = (reason: Reason): boolean =>
    selectedReasons[getReasonString(reason)] !== false

  const onSubmit = (): void => {
    updateFeedbackMutation.mutate({
      id: feedback.id,
      userRcId,
      rating,
      reasons: selectedReasons,
      comment,
    })
  }

  const disabled =
    updateFeedbackMutation.isPending ||
    (isSelectedReasonsEmpty(selectedReasons) && comment.trim().length === 0)

  return (
    <div className={vstack({alignItems: "stretch", gap: 20})}>
      {!on && <EvaluationOverall rating={rating} onRate={onSetRating} />}
      <div className={vstack({alignItems: "stretch", gap: 4, paddingTop: 8})}>
        {!on && (
          <h5
            className={css({
              textStyle: "h5",
            })}
          >
            {t(`feedback.general.${rating}.title`)}
          </h5>
        )}
        <div className={hstack({gap: 16})}>
          {isInline && <Logo size="small" />}
          <p
            className={css({
              textStyle: "body",
              color: isInline ? "fontBlack" : "fontGrey",
            })}
          >
            {on?.on === "remediation"
              ? t(`feedback.remediation.${on.type}.${rating}.message`)
              : on?.on === "message"
                ? t(`feedback.message.${rating}.message`)
                : t(`feedback.general.${rating}.message`)}
          </p>
        </div>
        <div
          className={hstack({
            flexWrap: "wrap",
            paddingY: 8,
          })}
        >
          {reasons.map((reason, index) => {
            const label = getReasonString(reason)

            return (
              <Chip
                type={isReasonSelected(reason) ? "chosen" : "unchosen"}
                title={label}
                onClick={() => {
                  onToggleReason(reason)
                }}
                className={css({
                  flexShrink: 0,
                })}
                key={index}
              />
            )
          })}
        </div>
        {reasons.map((reason, index) => {
          if (!isReasonSelected(reason) || reasonIsString(reason)) {
            return null
          }

          return (
            <Options
              prompt={reason.prompt}
              selectedOptions={
                selectedReasons[getReasonString(reason)] as Record<
                  string,
                  boolean
                >
              }
              onToggleOption={(option) => {
                onToggleOption(reason, option)
              }}
              key={index}
            />
          )
        })}
        <textarea
          cols={1}
          placeholder={t("feedback.commentPlaceholder")}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          disabled={updateFeedbackMutation.isPending}
          className={css({
            backgroundColor: "lightGrey",
            borderRadius: 12,
            textStyle: "body2",
            padding: 16,
            outline: "none",
            resize: "none",
            height: {base: 160, desktop: 128},
            color: "fontBlack",
            minWidth: 0,
            _placeholder: {
              color: "darkGrey",
            },
          })}
        />
      </div>
      <div className={hstack({justifyContent: "flex-end"})}>
        <Button
          variant="secondary"
          onClick={onCanceled}
          size="large"
          title={t("general.cancel")}
          css={css.raw({
            width: {
              base: "100%",
              desktop: "auto",
            },
          })}
        />
        <Button
          variant="primary"
          size="large"
          onClick={onSubmit}
          title={t("feedback.submit")}
          disabled={disabled}
          css={css.raw({
            width: {
              base: "100%",
              desktop: 150,
            },
          })}
        />
      </div>
    </div>
  )
}

type Reason = string | {label: string; prompt: string; options: string[]}

const createSelectedReasonsFromReasons = (
  reasons: Reason[],
  currentSelectedReasons: SelectedReasons | null,
): SelectedReasons => {
  const selectedReasons = currentSelectedReasons
    ? {...currentSelectedReasons}
    : {}

  const reasonStrings = reasons.map(getReasonString)

  for (const reason of Object.keys(selectedReasons)) {
    if (!reasonStrings.includes(reason)) {
      delete selectedReasons[reason]
    }
  }

  for (const reason of reasonStrings) {
    const reasonString = getReasonString(reason)

    if (selectedReasons[reasonString] === undefined) {
      selectedReasons[reasonString] = false
    }
  }

  return selectedReasons
}

const isSelectedReasonsEmpty = (selectedReasons: SelectedReasons): boolean =>
  Object.values(selectedReasons).every((value) => !value)

const toggleReason = (
  selectedReasonsOriginal: SelectedReasons,
  reason: Reason,
): SelectedReasons => {
  const selectedReasons = cloneDeep(selectedReasonsOriginal)

  const reasonString = getReasonString(reason)

  if (reasonIsString(reason)) {
    selectedReasons[reasonString] = !selectedReasons[reasonString]
  } else {
    if (selectedReasons[reasonString] === false) {
      selectedReasons[reasonString] = createRecordFromOptions(reason.options)
    } else {
      selectedReasons[reasonString] = false
    }
  }

  return selectedReasons
}

const toggleOption = (
  selectedReasonsOriginal: SelectedReasons,
  reason: Reason,
  option: string,
): SelectedReasons => {
  const selectedReasons = cloneDeep(selectedReasonsOriginal)

  if (reasonIsString(reason)) {
    throw new Error("Expected an object reason")
  }

  const reasonString = getReasonString(reason)

  if (selectedReasons[reasonString] === false) {
    throw new Error("Toggling an option in an unselected reason")
  }

  const options = selectedReasons[reasonString] as Record<string, boolean>

  if (options[option] === undefined) {
    throw new Error("Toggling a non-existent option")
  }

  options[option] = !options[option]

  return selectedReasons
}

const reasonIsString = (reason: Reason): reason is string =>
  typeof reason === "string"

const getReasonString = (reason: Reason): string => {
  if (reasonIsString(reason)) {
    return reason
  }
  return reason.label
}

const createRecordFromOptions = (
  options: string[],
): Record<string, boolean> => {
  const record: Record<string, boolean> = {}
  for (const option of options) {
    record[option] = false
  }
  return record
}
