import {FC, RefObject} from "react"
import {ReactSVG} from "react-svg"

import {InputField} from "@frontend/components"
import {IconButton} from "@frontend/components/ui/button/icon-button"
import arrowUpIcon from "@frontend/design/icons/arrow-up.svg"
import stopIcon from "@frontend/design/icons/stop.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"

// Keep in sync with app/src/app/api/addHumanMessageToCase/route.ts
const MAX_MESSAGE_LENGTH = 3000

interface Props {
  value: string
  onFocus?: () => void
  onChange: (value: string) => void
  onSend: () => void
  onAbort: () => void
  disabled?: boolean
  isReceiving?: boolean
  inputRef?: RefObject<HTMLTextAreaElement | null>
  className?: string
  isFirstMessage?: boolean
}

export const ConversationInputField: FC<Props> = ({
  value,
  onFocus,
  onChange,
  onSend,
  onAbort,
  disabled,
  isReceiving,
  inputRef,
  className,
  isFirstMessage,
}) => {
  const t = useTranslation()

  const placeholder = t(
    isFirstMessage ? "case.inputInitialPlaceholder" : "case.inputPlaceholder",
  )

  return (
    <InputField
      value={value}
      maxLength={MAX_MESSAGE_LENGTH}
      placeholder={placeholder}
      onFocus={onFocus}
      onChange={onChange}
      onEnter={onSend}
      disabled={disabled}
      inputRef={inputRef}
      rightComponent={
        isReceiving ? (
          <IconButton
            onClick={onAbort}
            ariaLabel={t("conversation.abort")}
            variant="unstyled"
            css={css.raw({
              maxHeight: 29,
              maxWidth: 20,
              cursor: "pointer",
            })}
            icon={<ReactSVG src={stopIcon} />}
          />
        ) : (
          <IconButton
            testId="submit-msg"
            variant="tertiaryBrand"
            size="large"
            icon={<ReactSVG src={arrowUpIcon} />}
            css={css.raw({width: 36, height: 36})}
            ariaLabel={t("conversation.send")}
            onClick={onSend}
            disabled={disabled}
          />
        )
      }
      className={className}
    />
  )
}
