import {z} from "zod"

import {assertOrNotFound, notFound} from "@frontend/utils/notFound"
import {trpc} from "@frontend/utils/trpc"
import {useStabilizedValue} from "@frontend/utils/useStabilizedValue"
import {useTypedParams} from "@frontend/utils/useTypedParams"
import {id} from "@frontend/utils/zod"
import type {
  RemediationPageData,
  RemediationPageDataRemediation,
} from "@ri2/app/server/routers/getRemediationPageData"

import {CaseIdParams} from "../../params"

export const CauseIdParams = z
  .object({
    causeOnMessageId: z.union([id, z.literal("peek")]),
  })
  .merge(CaseIdParams)

export const useDataFromParams = (): {
  causeOnMessageId: number | "peek"
  caseId: string
  remediationPageData: RemediationPageData
} => {
  const {causeOnMessageId, caseId} = useTypedParams(CauseIdParams)

  const [remediationPageData] = trpc.remediationPageData.useSuspenseQuery({
    caseId,
    causeOnMessageId,
  })

  assertOrNotFound(remediationPageData)

  return useStabilizedValue({
    causeOnMessageId,
    caseId,
    remediationPageData,
  })
}

export const getRemediationTypeRemediationPageDataOrNotFound = (
  remediationPageData: RemediationPageData,
): RemediationPageDataRemediation => {
  if (remediationPageData.type.type !== "remediation") {
    notFound()

    // This is just to satisfy TS. `notFound` throws, so this won't be
    // reached.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any as RemediationPageDataRemediation
  }

  return remediationPageData.type
}
