import {notFound} from "@frontend/utils/notFound"
import {trpc} from "@frontend/utils/trpc"
import {useStabilizedValue} from "@frontend/utils/useStabilizedValue"
import {useTypedParams} from "@frontend/utils/useTypedParams"
import type {
  RemediationDiyPageData,
  RemediationPageData,
} from "@ri2/app/server/routers/getRemediationPageData"

import {CauseIdParams} from "../params"

export const useDataFromParams = (): {
  causeOnMessageId: number
  caseId: string
  remediationPageData: RemediationPageData
  diyPageData: RemediationDiyPageData
} => {
  const {caseId, causeOnMessageId} = useTypedParams(CauseIdParams)

  if (causeOnMessageId === "peek") {
    notFound()
  }

  // This is just to satisfy TS. notFound throws, so causeOnMessageId
  // is guaranteed to be a number at this point.
  const causeOnMessageIdNumber = causeOnMessageId as number

  const [[remediationPageData, diyPageData]] = trpc.useSuspenseQueries((t) => [
    t.remediationPageData({caseId, causeOnMessageId}),
    t.remediationDiyPageData({causeOnMessageId: causeOnMessageIdNumber}),
  ])

  return useStabilizedValue({
    caseId,
    causeOnMessageId: causeOnMessageIdNumber,
    remediationPageData,
    diyPageData,
  })
}
