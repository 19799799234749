import i18next from "i18next"
import resourcesToBackend from "i18next-resources-to-backend"
import {
  initReactI18next,
  useTranslation as useTranslationBase,
} from "react-i18next"

import {addFormatters} from "./formatters"

const language = "en"
const defaultNS = "translation"

i18next
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`./locales/${language}/${namespace}.json`),
    ),
  )
  .init({
    lng: language,
    ns: defaultNS,
  })
addFormatters(i18next)

export const useTranslation = (): ReturnType<
  typeof useTranslationBase
>["t"] => {
  const {t} = useTranslationBase()
  return t
}
