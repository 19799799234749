import {
  APILoadingStatus,
  APIProvider,
  APIProviderProps,
  useApiLoadingStatus,
} from "@vis.gl/react-google-maps"
import {FunctionComponent, PropsWithChildren, ReactNode, useEffect} from "react"

// Taken directly from https://visgl.github.io/react-google-maps/docs/guides/migrating-from-react-wrapper

const STATUS_MAP = {
  [APILoadingStatus.LOADING]: "LOADING",
  [APILoadingStatus.LOADED]: "SUCCESS",
  [APILoadingStatus.FAILED]: "FAILURE",
} as const

type WrapperProps = PropsWithChildren<
  {
    apiKey: string
    callback?: (status: string) => void
    render?: (status: string) => ReactNode
  } & APIProviderProps
>

export const GoogleMapsWrapper: FunctionComponent<WrapperProps> = ({
  apiKey,
  children,
  render,
  ...apiProps
}) => (
  <APIProvider apiKey={apiKey} {...apiProps}>
    <InnerWrapper render={render}>{children}</InnerWrapper>
  </APIProvider>
)

const InnerWrapper: FunctionComponent<
  PropsWithChildren<Omit<WrapperProps, "apiKey">>
> = ({callback, render, children}) => {
  const status = useApiLoadingStatus()
  const mappedStatus = STATUS_MAP[status] ?? "LOADING"

  useEffect(() => {
    if (callback) callback(mappedStatus)
  }, [callback, mappedStatus])

  if (status === APILoadingStatus.LOADED) return children
  if (render) return render(mappedStatus)

  return <></>
}
