import {FC, PropsWithChildren} from "react"
import {Link} from "react-router-dom"
import {ReactSVG} from "react-svg"

import {Button} from "@frontend/components"
import chevronRight from "@frontend/design/icons/chevron-right.svg"
import homeVector from "@frontend/design/icons/home-vector.svg"
import {useTranslation} from "@frontend/i18n"
import {makePath} from "@frontend/routing"
import {trackEvent} from "@frontend/utils/trackEvent"
import {UiCauses} from "@ri2/app/server/routers/getUiCauses"
import {CauseOnMessageWithCauseAndPart} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack, iconsColor, vstack} from "@styled-system/patterns"
import {Token} from "@styled-system/tokens"

import {CauseIcon} from "./cause-icon"

const styles = {
  notConfirmed: {
    descriptionColor: "text.tertiary",
    titleColor: "text.primary",
    borderColor: "border.secondary.200",
    chevronColor: "foreground.brand.accent",
    paddingX: 8,
    iconBackground: "colors.background.tertiary" as const,
    iconColor: "foreground.tertiary" as const,
  },
  confirmed: {
    descriptionColor: "text.tertiary",
    titleColor: "text.primary",
    borderColor: "transparent",
    chevronColor: "foreground.brand.accent",
    paddingX: 12,
    iconBackground: "colors.background.tertiary" as const,
    iconColor: "foreground.primaryOnSolid" as const,
  },
}

interface Props {
  caseId: string
  cause: CauseOnMessageWithCauseAndPart
  bucket: keyof UiCauses
  className?: string
  setHoveredItem: (id: number | null) => void
  isHighlighted: boolean
  iconBackground: Token
  isInConversation?: boolean
}

export const Cause: FC<Props> = ({
  caseId,
  cause,
  className,
  bucket,
  setHoveredItem,
  isHighlighted,
  isInConversation,
  iconBackground,
}) => {
  const t = useTranslation()

  const style =
    bucket === "confirmed" ? styles["confirmed"] : styles["notConfirmed"]

  return (
    <div
      className={css(
        {
          padding: 1,
          position: "relative",
          overflowX: "visible",
          backgroundColor: "transparent",
          borderTopWidth: 1,
          borderTopColor: style.borderColor,
          width: "100%",
        },
        bucket === "confirmed" && {
          borderTopWidth: 0,
          borderRadius: 16,
          backgroundColor: "background.primary",
          zIndex: "causes.container",
          _hover: {
            bgGradient: cause.cause.isNormalOperation
              ? undefined
              : "causesHoverBorderGradient",
          },
        },
        isInConversation && {
          maxWidth: 540,
        },
      )}
      onMouseEnter={
        cause.cause.isNormalOperation
          ? undefined
          : (): void => {
              setHoveredItem(cause.id)
            }
      }
    >
      {isHighlighted &&
        bucket === "confirmed" &&
        !cause.cause.isNormalOperation && (
          <div
            className={css({
              position: "absolute",
              top: "-23.29px",
              right: "-24.93px",
              desktopOnly: {
                top: "-13.76px",
                right: "-14.76px",
              },
              zIndex: "causes.sparkle",
            })}
          >
            <ReactSVG
              src={homeVector}
              className={cx(
                iconsColor({color: "background.brand.accent"}),
                css({
                  "& svg": {
                    width: "49.86px",
                    height: "49.86px",
                    desktopOnly: {
                      width: "30.72px",
                      height: "30.72px",
                    },
                  },
                }),
              )}
            />
          </div>
        )}
      <LinkContainer
        caseId={caseId}
        cause={cause}
        className={className}
        bucket={bucket}
      >
        <CauseIcon
          color={
            bucket === "confirmed"
              ? (iconBackground ?? style.iconBackground)
              : style.iconBackground
          }
          className={css({flexShrink: 0, flexGrow: 0})}
          iconName={cause.cause.iconName}
          key={cause.cause.iconName}
          iconColor={style.iconColor}
        />
        <div
          className={vstack({
            gap: 0,
            alignItems: "flex-start",
            flexGrow: 1,
            width: "auto",
          })}
        >
          <h3
            className={css({
              color: style.titleColor,
              textStyle: "bodyMedium",
            })}
          >
            {cause.cause.name}
          </h3>
          <p
            className={css({
              color: style.descriptionColor,
              overflow: "hidden",
              textOverflow: "ellipsis",
              textStyle: "captionRegular",
              lineClamp: 2,
            })}
          >
            {cause.cause.description}
          </p>
        </div>
        {!cause.cause.isNormalOperation && (
          <div
            className={hstack({
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingY: 10,
            })}
          >
            {bucket === "confirmed" ? (
              <Button
                variant="secondary"
                onClick={() => ({})}
                css={css.raw({
                  whiteSpace: "nowrap",
                  paddingX: 16,
                  desktopOnly: {},
                })}
                rightElement={
                  <ReactSVG
                    src={chevronRight}
                    className={hstack({
                      width: 20,
                      height: 20,
                      alignItems: "center",
                      justifyContent: "center",
                    })}
                  />
                }
              >
                <span
                  className={css({
                    "@container (max-Width: 300px)": {
                      display: "none",
                    },
                  })}
                >
                  {t(
                    isInConversation
                      ? "cases.case.viewMore"
                      : "cases.case.view",
                  )}
                </span>
              </Button>
            ) : (
              <ReactSVG
                src={chevronRight}
                className={cx(
                  hstack({
                    minWidth: 32,
                    width: 20,
                    height: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }),
                  iconsColor({
                    color: style.chevronColor ?? "base.white",
                  }),
                )}
              />
            )}
          </div>
        )}
      </LinkContainer>
    </div>
  )
}

interface LinkContainerProps {
  caseId: string
  cause: CauseOnMessageWithCauseAndPart
  bucket: keyof UiCauses
  className?: string
}

const LinkContainer: FC<PropsWithChildren<LinkContainerProps>> = ({
  caseId,
  cause,
  bucket,
  className,
  children,
}) => {
  const path = makePath({
    name: "cases",
    caseId,
    causeOnMessageId: cause.id,
  })

  const onClick = (): void => {
    trackEvent("chat_cause")
  }

  const style =
    bucket === "confirmed" ? styles["confirmed"] : styles["notConfirmed"]

  const c = cx(
    hstack({
      width: "100%",
      overflow: "hidden",
      paddingY: 16,
      paddingX: style.paddingX,
      gap: 12,
      position: "relative",
      alignItems: "center",
      boxSizing: "border-box",
    }),
    bucket === "confirmed" &&
      css({
        containerName: "cause",
        containerType: "inline-size",
        borderTopWidth: 0,
        borderRadius: 16,
        backgroundColor: "background.primary",
        position: "relative",
        zIndex: "causes.content",
        _hover: cause.cause.isNormalOperation
          ? undefined
          : {
              borderStyle: "solid",
              bgGradient: "causesHoverGradient",
            },
      }),
    className,
  )

  return cause.cause.isNormalOperation ? (
    <div className={c}>{children}</div>
  ) : (
    <Link className={c} to={path} replace onClick={onClick}>
      {children}
    </Link>
  )
}
